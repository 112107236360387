export default {
  namespaced: true,
  state() {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {
    initialize({ dispatch }) {
      console.info('Module initializing...');
      console.info('Module initialized.');
    },
  },
};
